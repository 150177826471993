
.App {
  font-size: 16px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  background-image: ('/img/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 960px) {
  .App {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .App {
    font-size: 12px;
  }
}

.logo {
  margin-right: auto;
}

.appBar {
  margin-bottom: 2em;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.mainContainer {
  width: 100%;
}

.gridContainer {
  justify-content: space-between;
}

.statCard {
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  color: white;
}

.statCard:hover {
  transform: scale(1.05); /* scales the card up slightly */
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); /* increases the shadow depth */
}

.statCard .CardContent {
  position: relative;
  overflow: hidden;
  color: white;
}

.statCard .CardContent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: transform 0.5s;
  transform: scaleX(0);
  transform-origin: left;
}

.statCard:hover .CardContent::before {
  transform: scaleX(1);
  transform-origin: right;
}

.statCard .CardContent h6,
.statCard .CardContent .body1 {
  position: relative;
  z-index: 2;
  color: white;
}


.actionCard {
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  background: linear-gradient(45deg, #47ecef53, #ff66cf4f);
  color: white;
}

.statCard,
.actionCard {
  color: white;
  border: 6px solid #eda81c;
}

.statCard {
  background-image: linear-gradient(rgba(239, 71, 111, 0.75), rgba(255, 209, 102, 0.75)), url('./img/cardBackground.jpg');
}

.actionCard {
  background-image: linear-gradient(rgba(222, 222, 222, 0.39), rgba(255, 102, 240, 0.4)), url('./img/buttons.jpg');
}

/* Update the text color of the fields and buttons */
.MuiInputBase-input,
.MuiButton-label {
  color: white !important;
}

/* Make the buttons more prominent */
.MuiButton-contained {
  background-color: #eda81c !important;
  color: white !important;
  font-weight: bold;
}

.MuiButton-contained:hover {
  transform: translateY(-2px);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}

.statCard,
.actionCard {
  border: 6px solid #eda81c;
}

.statCard {
  background-image: linear-gradient(rgba(239, 71, 110, 0.347), rgba(255, 209, 102, 0.462)), url('./img/cardBackground.jpg');
}

.actionCard {
  background-image: linear-gradient(rgba(222, 222, 222, 0.39), rgba(255, 102, 240, 0.4)), url('./img/buttons.jpg');
}

